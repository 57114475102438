.box {
  height: 130px;
  width: 130px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border: 1px solid #efefef;
  background-color: white;
}

.box .box-title {
  padding: 2px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  height: fit-content;
}

.box .running {
  height: 100%;
  display: grid;
  place-items: center;
}

.active {
  color: green;
  font-size: 18px;
  font-weight: 300;
}
.in-active {
  font-size: 18px;
  color: red;
  font-weight: 300;
}
