.dropdown {
  position: relative;
  width: 330px;
}

.dropdown input {
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #cececf;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  transition: all 200ms ease;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  cursor: initial;
  padding: 8px;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option:hover {
  background: #ebecee;
}

.dropdown input:focus {
  box-shadow: 0 0 0 2px #d6d1ff;
}
