.Page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.DashBoards_Container{
    min-width: 60vw;
    min-height: 60vh;
    border-radius: 5px;
    box-shadow: 0px 0px 2px rgba(8, 12, 43, 0.16);
}

.DashBoards_Header{
    padding: 10px;
    font-weight: 600;
}

.Card_Container{
    display: flex;
    flex-direction: row;
}

.Card{
    width: 100%;
    max-width: 300px;
    height: 100%;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(8, 12, 43, 0.16);
    margin: 10px;
}

.top-bar {
  width: 100%;
  padding: 20px;
}
.top-bar div {
  float: right;
  display: flex;
  align-items: center;
}

.add-user {
  margin: 0 15px;   
  cursor: pointer;
}
.health-view {
  cursor: pointer;
}

.add-user-form {
  padding: 5px 20px;
  display: grid;
  place-items: center;
}

.form-field .field-name {
  padding: 10px 0;
}

.AI_Prompt_Dashboard_Container{
  padding: 20px;
}