.error-notification {
    color: #7d0000;
    background: #fff4f0;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #7d0000;
    font-size: medium;
}

.search-input{
    margin: 0px 10px 10px 10px;
}