.background {
  background: #0d114a;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.background .klenty-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 100px;
  cursor: pointer;
}

.heath-dashboard {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  height: 100%;
  padding-bottom: 20px;
}

.dashboard-header {
  margin: 30px 0 10px 0;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  padding: 0 10px;
  color: white;
}

.dashboard-header h2 {
  width: 100%;
  text-align: center;
}

.cluster-wrapper {
  background: white;
  padding: 0 20px;
  border-radius: 5px;
  height: calc(100% -110px);
  overflow-y: auto;
}

.loading {
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
}
