.statsCard {
  width: 100%;
  margin: 20px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #efefef;
}

.cluster-title {
  color: gray;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  display: flex;
  flex-shrink: 0;
  display: block;
  text-align: center;
  margin: 10px;
  text-transform: uppercase;
}

.stats-layout {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 20px;
  width: 100%;
  background-color: white;
}

@media (max-width: 1240px) {
  .statsCard {
    flex-wrap: wrap;
  }

  .cluster-title {
    text-align: left;
  }
}
