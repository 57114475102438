.Loading-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-weight: 600;
}

.ai-prompt-dashboard__no-result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 40px 20px;
}
  
.ai-prompt-dashboard__header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}
  
.ai-prompt-dashboard__no-result-message {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    text-align: center;
    color: #333;
}
  
.ai-prompt-dashboard__no-result-message p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #666;
}
  
.ai-prompt-dashboard__no-result-message p:last-child {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #888;
}

.ai-prompt-dashboard__no-result-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.ai-prompt-dashboard__error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f7;
    padding: 40px 20px;
}
  
.ai-prompt-dashboard__error-message {
    background-color: #fff;
    border: 1px solid #f44336;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    text-align: center;
    color: #f44336;
}
  
.ai-prompt-dashboard__error-message p {
    font-weight: 500;
    margin-top: 10px;
    font-size: 14px;
    color: #666;
}  

.ai-prompt-dashboard{
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.ai-prompt-dashboard__header{
    font-size: 20px;
    line-height: 30px;
    color: #080C2BCC;
    font-weight: 600;
    padding: 8px 0px;
}

.ai-prompt-dashboard__filter{
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 6px 8px;
    border: 1px solid #EBECEE;
    border-radius: 4px;
}

.ai-prompt-dashboard__filter-label{
    font-size: 14px;
    line-height: 20px;
    color: #080C2BCC;
    font-weight: 600;
}

.ai-prompt-dashboard__filter-list{
    display: flex;
    gap: 8px;
}

.ai-prompt-dashboard__filter-item{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 2px 4px;
    border-radius: 2px;
    background-color: #EBECEE;
}

.ai-prompt-dashboard__filter-item-label{
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #080C2BCC;
}

.ai-prompt-dashboard__filter-item-icon{
    cursor: pointer;
}

.ai-prompt-dashboard__filter-icon{
    cursor: pointer;
}

.ai-prompt-dashboard__filter-item-summary{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 100px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    background-color: #f5f5f7;
    color: #080c2bcc;
    cursor: default;
}

.custom-table {
    border: 1px solid #EBECEECC; 
    border-radius: 4px;
}

.custom-table table thead tr{
    height: 32px;
}

.custom-table table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th {
    border: 1px solid #EBECEECC;
    padding: 8px;
    text-align: left;
    color: #080C2B80;
  }
  
.custom-table td {
    border: 1px solid #EBECEECC;
    padding: 8px;
    text-align: left;
    height: 76px;
    padding: 8px;
    vertical-align: baseline;
}
  
.custom-table tr:hover {
    background-color: #F5F5F799;
    cursor: pointer;
}

.custom-table tr td:nth-child(1) {
    width: 20%;
  }

.custom-table tr td:nth-child(2) {
    width: 20%;
}

.custom-table tr td:nth-child(3) {
    width: 30%;
}
  
.custom-table tr td:last-child {
    width: 30%;
}

.custom-table tr:last-child td {
    padding: 0; 
    border:none;
    background-color: #F5F5F7;
    height: 24px;
}

.clamped-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 2.5em;
    line-height: 1.2em;
    flex: 1;
}

.description-panel__container{
    width: 40% !important;
    padding: 16px !important;
}

.description-panel__header{
    color: #080C2BCC;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
}

.description-panel__content{
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    grid-area: 16px;
}

.description-panel__textarea{
    max-height: 500px;
}

.actions-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.description-panel__button-cancel,
.description-modal__button-cancel{
    color: #080C2BCC !important;
    border-color: #080C2BCC !important;
}
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .add-new_buton {
    color: #080C2BCC !important;
    border-color: #080C2BCC !important;
    width: 5rem;
    cursor: pointer;
  }
.description-panel__button-save,
.description-modal__button-save{
    background-color: #080C2B !important;
    color: #fff !important;
}

.description-modal__form-container{
    padding: 16px 24px;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
}

.description-modal__form-input-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
}

.description-modal__description-textarea {
    width: 100%;
    height: 100%;
}

.description-modal__prompt-textarea{
    width: 50%;
    height: 100%;
}

.description-modal__prompt-textarea-label{
    color: #080c2bcc;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
}

